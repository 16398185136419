'use strict';
import ChargedAPI from '../clients/ChargedAPI'

const docType = "Contact";

var contact = {
    first_name: "Bike Subscribtion Lead",
    middle_name: "",
    last_name:"",
    gender: "",
    email_id: "",
    mobile_no: 0,
    phone: 0,
    email_address_2:"",
    email_ids: [],
    phone_nos:[]
}

function createContact()
{
    Promise.resolve(ChargedAPI.createResource(docType, contact))
        .then(result => {
          console.log(result)
        })
        .catch(err => {
          console.log(err)
    })
}

export {contact, createContact};
