<template>
  <div
    id="home"
    :style="
      dark
        ? 'background-color: #2a2a2a;height:100%'
        : 'background-color: #FFF;height:100%'
    "
  >
    <v-slide-y-transition mode="out-in">
      <v-layout column>
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout
          column
          align-center
          v-show="loading"
          :style="windowWidth < 770 ? 'margin-top:30px' : 'margin-top:150px;'"
        >
          <p class="mt-2"></p>

          <v-progress-circular
            :size="70"
            :width="7"
            color="deep-purple"
            indeterminate
          ></v-progress-circular>
        </v-layout>

        <!-- ############################################## COMPONENTS  ##############################################  -->

        <v-layout column v-show="!loading">
          <v-layout id="home" column>
            <!-- SLIDER HERO BANNER -->
            <v-carousel
              v-show="$vuetify.breakpoint.lgAndUp"
              cycle
              style="border-radius: 0px"
              dark
              :width="'100%'"
              :height="'100%'"
              :min-height="windowHeight"
              hide-delimiters
              hide-delimiter-background
              :show-arrows="true"
              :interval="7000"
            >
              <!-- <a @click="scrollToAnchorPoint('gotobikeshome')">
                <v-carousel-item class="bg-lebaran" cover :min-height="windowHeight" :max-height="windowHeight">
                </v-carousel-item>
              </a> -->

              <!-- <a @click="scrollToAnchorPoint('gotobikeshome')">
                <v-carousel-item
                  class="bg-promo"
                  cover
                  :min-height="windowHeight"
                  :max-height="windowHeight"
                >
                </v-carousel-item>
              </a> -->

              <v-carousel-item
                class="bg-rimau-s"
                cover
                :min-height="windowHeight"
                :max-height="windowHeight"
              >
                <div class="p-20">
                  <v-img
                    class="bike-logo mr-auto -ml-5"
                    src="/img/shared/bikes/rimau_s/logotype-rimau_s.png"
                    :width="'380px'"
                    max-width="327px"
                  ></v-img>

                  <p
                    class="text-white mt-28 text-6xl font-light font-montserrat mb-0"
                  >
                    {{
                      getLanguage == "en" ? "SPECIAL PRICE" : "HARGA SPESIAL"
                    }}
                  </p>
                  <p class="text-white font-bold font-montserrat">
                    {{
                      getLanguage == "en"
                        ? "WITH GOVERNMENT SUBSIDY"
                        : "DENGAN SUBSIDI PEMERINTAH"
                    }}
                  </p>

                  <v-btn
                    outlined
                    class="text-capitalize"
                    color="white"
                    @click="$router.push('/bike/rimau_s')"
                  >
                    {{
                      getLanguage == "en" ? "Get it now!" : "Dapatkan Sekarang!"
                    }}
                  </v-btn>

                  <v-img
                    src="/img/logo/TKDN-White.png"
                    class="mt-28"
                    max-width="261"
                  />
                </div>
              </v-carousel-item>

              <v-carousel-item
                class="bg-home"
                cover
                :min-height="windowHeight"
                :max-height="windowHeight"
              >
                <v-layout column style="margin-left: 100px; margin-top: 250px">
                  <span
                    class="homeTitleNew white--text"
                    style="margin-bottom: 8px; letter-spacing: -2.04px"
                    >{{ lang[getLanguage].SLOGAN }}</span
                  >
                  <span
                    class="work-sans white--text"
                    style="
                      font-size: 20px;
                      line-height: 28px;
                      max-width: 680px;
                      letter-spacing: -0.4px;
                    "
                    >{{ lang[getLanguage].SLOGAN_DESCRIPTION }}
                  </span>
                  <!-- <v-btn
                    color="#6B4693"
                    large
                    :to="'/testride/home'"
                    class="work-sans font-medium text-base"
                    :style="
                      windowWidth < 770
                        ? 'width:100%; color:#FFF; text-transform: none !important; border-radius: 8px; margin-top: 10px; letter-spacing: -0.32px; font-size: 16px !important; height: 52px;'
                        : 'width:300px; color:#FFF; text-transform: none !important; border-radius: 8px; margin-top: 24px; letter-spacing: -0.32px; font-size: 16px !important; height: 52px;'
                    "
                  >
                    {{ lang[getLanguage].TEST_RIDE_SHOWROOM }}
                    <v-icon right style="color: #535456; font-size: 20px">
                      mdi-chevron-right
                    </v-icon>
                  </v-btn> -->
                </v-layout>
              </v-carousel-item>
            </v-carousel>

            <v-carousel
              v-show="$vuetify.breakpoint.mdAndDown"
              cycle
              style="border-radius: 0px"
              dark
              :width="'100%'"
              :height="'100%'"
              :min-height="windowHeight"
              hide-delimiters
              hide-delimiter-background
              :show-arrows="false"
            >
              <!-- <a @click="scrollToAnchorPoint('gotobikeshome')">
                <v-carousel-item class="bg-lebaran-mobile" cover>
                </v-carousel-item>
              </a> -->

              <!-- <a @click="scrollToAnchorPoint('gotobikeshome')">
                <v-carousel-item class="bg-promo-mobile" cover>
                </v-carousel-item>
              </a> -->

              <v-carousel-item
                class="bg-rimau-s-mobile relative"
                cover
                :min-height="windowHeight"
                :max-height="windowHeight"
              >
                <div class="pt-20 relative">
                  <div style="width: 50%" class="mx-auto -mt-10 mb-10">
                    <v-img
                      class="mx-auto"
                      src="/img/shared/bikes/rimau_s/logotype-rimau_s.png"
                    ></v-img>
                  </div>

                  <p
                    class="text-white mt-0 text-4xl font-light font-montserrat mb-0 text-center"
                  >
                    {{
                      getLanguage == "en" ? "SPECIAL PRICE" : "HARGA SPESIAL"
                    }}
                  </p>
                  <p
                    class="text-white font-bold font-montserrat text-center mb-10"
                  >
                    {{
                      getLanguage == "en"
                        ? "WITH GOVERNMENT SUBSIDY"
                        : "DENGAN SUBSIDI PEMERINTAH"
                    }}
                  </p>
                </div>
                <div class="absolute bottom-14 w-full">
                  <v-img
                    src="/img/logo/TKDN-White.png"
                    class="mx-auto mb-10"
                    max-width="261"
                  />
                  <div class="d-flex justify-center">
                    <v-btn
                      outlined
                      large
                      class="text-capitalize"
                      color="white"
                      @click="$router.push('/bike/rimau_s')"
                    >
                      {{
                        getLanguage == "en"
                          ? "Get it now!"
                          : "Dapatkan Sekarang!"
                      }}
                    </v-btn>
                  </div>
                </div>
              </v-carousel-item>

              <v-carousel-item
                class="bg-home-mobile"
                cover
                :min-height="windowHeight"
                :max-height="windowHeight"
              >
                <v-layout
                  column
                  align-center
                  style="margin-top: 90px; margin-left: 5%; margin-right: 5%"
                >
                  <Link
                    style="margin: 0; padding: 0"
                    :text="'Powered by VMOTOSOCO'"
                    class="white--text mb-12"
                    actionParam="https://en.vmotosoco.com/"
                    @actions="goToLink"
                  />
                  <span
                    class="homeTitleNewMobile white--text text-4xl mb-2"
                    style="line-height: 45px; letter-spacing: -1.08px"
                    >{{ lang[getLanguage].SLOGAN }}</span
                  >
                  <span
                    style="
                      font-size: 14px;
                      color: #fff;
                      letter-spacing: -0.28px;
                      font-weight: 400;
                    "
                    class="work-sans font-normal text-center white--text mb-6"
                    >{{ lang[getLanguage].SLOGAN_DESCRIPTION }}
                  </span>
                  <!-- <v-btn
                    color="#6B4693"
                    large
                    :to="'/testride/home'"
                    class="work-sans"
                    :style="
                      windowWidth < 770
                        ? 'width:100%;color:#FFF;text-transform: none !important; border-radius: 8px; margin-top: 0px;'
                        : 'width:300px;color:#FFF;text-transform: none !important; border-radius: 8px; margin-top: 0px;'
                    "
                  >
                    {{ lang[getLanguage].TEST_RIDE_SHOWROOM }}
                    <v-icon right style="color: #535456; font-size: 14px">
                      mdi-chevron-right
                    </v-icon>
                  </v-btn> -->
                  <p class="mt-2"></p>
                </v-layout>
              </v-carousel-item>
            </v-carousel>

            <!-- END SLIDER HERO BANNER -->

            <!-- BANNER NON SLIDER -->
            <!-- <v-card v-if="!isMobileDevice" flat style="border-radius: 0px" :dark="dark" :width="'100%'"
              :min-height="windowHeight">
              <v-img src="/img/home/homepage-banner-illustration.jpg" :min-height="windowHeight"
                :max-height="windowHeight">
                <v-layout column style="margin-left: 100px; margin-top: 250px">
                  <span class="homeTitleNew white--text"
                    style="margin-bottom: 8px">{{ lang[getLanguage].SLOGAN }}</span>
                  <span class="work-sans white--text" style="
                      font-size: 16px;
                      line-height: 28px;
                      max-width: 600px;
                      letter-spacing: -0.02em;
                    ">{{ lang[getLanguage].SLOGAN_DESCRIPTION }}
                  </span>
                  <v-btn color="#6B4693" large :to="'/testride/home'" class="work-sans" :style="
                      windowWidth < 770
                        ? 'width:100%;color:#FFF;text-transform: none !important; border-radius: 8px; margin-top: 10px;'
                        : 'width:300px;color:#FFF;text-transform: none !important; border-radius: 8px; margin-top: 20px;'
                    ">
                    {{ lang[getLanguage].TEST_RIDE_SHOWROOM }} &nbsp;
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.99967 0.333313L5.82467 1.50831L10.4747 6.16665H0.333008V7.83331H10.4747L5.82467 12.4916L6.99967 13.6666L13.6663 6.99998L6.99967 0.333313Z"
                        fill="white" />
                    </svg>
                  </v-btn>
                </v-layout>
              </v-img>

            </v-card>

            <v-card flat v-if="isMobileDevice" style="border-radius: 0px" :dark="dark" :width="'100%'"
              :min-height="windowHeight" :class="dark ? 'd-lg-none d-flex' : 'd-lg-none d-flex'">
              <v-img class="bg-home">
                <v-layout column align-center style="margin-top: 90px; margin-left: 5%; margin-right: 5%">
                  <Link style="margin: 0; padding: 0" :text="'Powered by VMOTOSOCO'" class="white--text mb-12"
                    actionParam="https://en.vmotosoco.com/" @actions="goToLink" />
                  <span class="homeTitleNewMobile white--text">{{
                    lang[getLanguage].SLOGAN
                  }}</span>
                  <span style="
                      font-size: 14px;
                      color: #fff;
                      letter-spacing: -0.02em;
                      font-weight: 400;
                    "
                    class="work-sans text-center font-weight-medium white--text">{{ lang[getLanguage].SLOGAN_DESCRIPTION }}
                  </span>
                  <v-btn color="#6B4693" large :to="'/testride/home'" class="work-sans" :style="
                      windowWidth < 770
                        ? 'width:100%;color:#FFF;text-transform: none !important; border-radius: 8px; margin-top: 10px;'
                        : 'width:300px;color:#FFF;text-transform: none !important; border-radius: 8px; margin-top: 20px;'
                    ">
                    {{ lang[getLanguage].TEST_RIDE_SHOWROOM }} &nbsp;
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.99967 0.333313L5.82467 1.50831L10.4747 6.16665H0.333008V7.83331H10.4747L5.82467 12.4916L6.99967 13.6666L13.6663 6.99998L6.99967 0.333313Z"
                        fill="white" />
                    </svg>
                  </v-btn>
                  <p class="mt-2"></p>
                </v-layout>
              </v-img>
            </v-card> -->
            <!-- END BANNER NON SLIDER -->
          </v-layout>

          <!-- ###################### Section Break ################ -->

          <!-- <div class="text-center">
            <v-dialog v-model="Promodialog" width="auto">
              <v-card v-show="$vuetify.breakpoint.lgAndUp">
                <v-layout>
                  <v-spacer></v-spacer>
                  <v-btn @click="Promodialog = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-layout>
                <v-layout>
                  <v-spacer></v-spacer>
                  <img src="/img/home/modal/maleoxdochi-withshadow.png" width="100%" />
                  <v-spacer></v-spacer>
                </v-layout>
                <v-card-text class="text-center work-sans"
                  style="font-size: 18px; letter-spacing: -0.02em; font-weight: 600; color: #6B4693; padding-bottom: 0px;">
                  {{lang[getLanguage].MODAL_DOCHI_TITLE}}
                </v-card-text>
                <v-col cols="12" sm="12" class="text-center d-flex justify-center">
                  <span class="montserrat font-weight-bold mr-2"
                    style="font-weight: 700; font-size: 36px; line-height: 45px; letter-spacing: -0.03em;">MALEO</span>
                  <img src="/img/home/modal/sadega-vision-logo.png" alt="" class="mt-6 mt-md-0 mr-2"
                    style="width: 36px;" />
                  <span class="montserrat font-weight-bold"
                    style="font-weight: 700; font-size: 36px; line-height: 45px; letter-spacing: -0.03em;">DOCHI</span>
                </v-col>
                <v-card-text class="font-weight-medium text-center work-sans"
                  style="font-size: 16px; line-height: 24px; letter-spacing: -0.02em;">
                  {{lang[getLanguage].MODAL_DOCHI_DESCRIPTION}}</v-card-text>
                <v-card-text class="text-center work-sans">
                  <v-btn color="#6B4693" large :to="'/maleoxdochi'" class="work-sans mr-6"
                    style="color: white; padding: 12px 24px; border-radius: 8px; width: 360px;">PRE-ORDER NOW
                  </v-btn>
                </v-card-text>
              </v-card>

              <v-card v-show="$vuetify.breakpoint.mdAndDown">
                <v-layout>
                  <v-spacer></v-spacer>
                  <v-btn @click="Promodialog = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-layout>
                <v-layout>
                  <v-spacer></v-spacer>
                  <img src="/img/home/modal/maleoxdochi-withshadow.png" width="100%" />
                  <v-spacer></v-spacer>
                </v-layout>
                <v-card-text class="text-center work-sans"
                  style="font-size: 18px; letter-spacing: -0.02em; font-weight: 600; color: #6B4693; padding-bottom: 0px;">
                  {{lang[getLanguage].MODAL_DOCHI_TITLE}}
                </v-card-text>
                <v-col cols="12" sm="12" class="text-center d-flex justify-center">
                  <span class="montserrat font-weight-bold mr-2"
                    style="font-weight: 700; font-size: 36px; line-height: 45px; letter-spacing: -0.03em;">MALEO</span>
                  <img src="/img/home/modal/sadega-vision-logo.png" alt="" class="mr-2" style="width: 36px;" />
                  <span class="montserrat font-weight-bold"
                    style="font-weight: 700; font-size: 36px; line-height: 45px; letter-spacing: -0.03em;">DOCHI</span>
                </v-col>
                <v-card-text class="font-weight-medium text-center work-sans"
                  style="font-size: 16px; line-height: 24px; letter-spacing: -0.02em;">
                  {{lang[getLanguage].MODAL_DOCHI_DESCRIPTION}}</v-card-text>
                <v-card-text class="text-center work-sans">
                  <v-btn color="#6B4693" large :to="'/maleoxdochi'" class="work-sans mr-6"
                    style="color: white; padding: 12px 24px; border-radius: 8px; width: 100%;">PRE-ORDER NOW
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div> -->

          <HomeIcons
            id="homeicons"
            :dark="dark"
            :drawer="drawer"
            :windowWidth="windowWidth"
          >
          </HomeIcons>

          <div ref="gotobikeshome">
            <BikesHome
              id="bikeshome"
              :dark="dark"
              :drawer="drawer"
              :isMobileDevice="isMobileDevice"
              :windowWidth="windowWidth"
            >
            </BikesHome>
          </div>

          <how-to-subs></how-to-subs>

          <!-- <HomeTestRide
            id="options"
            :isMobileDevice="isMobileDevice"
            :dark="dark"
            :drawer="drawer"
            :windowWidth="windowWidth"
          >
          </HomeTestRide> -->
          <v-footer class="pa-0">
            <Footer
              id="footer"
              :isMobileDevice="isMobileDevice"
              :dark="dark"
              :drawer="drawer"
              :windowWidth="windowWidth"
            >
            </Footer>
          </v-footer>

          <!-- <About id="about" :dark="dark" :drawer="drawer" :windowWidth="windowWidth">
          </About> -->

          <!-- <NewsReal id="newsreal" :dark="dark" :drawer="drawer" :windowWidth="windowWidth">
          </NewsReal> -->

          <!-- <v-parallax
            height="80"
            src="/img/home/paralax_02.jpeg"
          ></v-parallax> -->

          <!-- <Careers id="careers" :isMobileDevice="isMobileDevice" :dark="dark" :drawer="drawer" :windowWidth="windowWidth" :windowHeight="windowHeight">
          </Careers> -->

          <!-- <Contact id="contact" :dark="dark" :drawer="drawer" :windowWidth="windowWidth" v-on:signUpClicked="signUpDialog = true"
                   v-on:termsClicked="openTerms" v-on:privacyClicked="openPrivacy">
          </Contact> -->
        </v-layout>
      </v-layout>
    </v-slide-y-transition>

    <!-- ############################################## BOTTOM NAVIGATION ##############################################  -->

    <v-layout column align-center pt-2>
      <!-- v-show="!loadingData" -->
      <v-bottom-navigation
        v-if="showBottomNav"
        :input-value="showBottomNav"
        color="#1B5E20"
        background-color="lime lighten-5"
        fixed
      >
        <v-btn style="padding-left: 20px; padding-right: 0px">
          <span>Queue</span>
          <v-icon>mdi-format-list-bulleted-triangle</v-icon>
        </v-btn>

        <v-btn style="padding-right: 0px; padding-left: 0px">
          <span>Launched</span>
          <v-icon>mdi-rocket-launch</v-icon>
        </v-btn>

        <v-btn style="padding-right: 0px; padding-left: 0px">
          <span>Promoted</span>
          <v-icon>mdi-bullhorn</v-icon>
        </v-btn>

        <v-btn style="padding-right: 0px; padding-left: 0px">
          <span>New</span>
          <v-icon>mdi-expand-all</v-icon>
        </v-btn>

        <v-btn style="padding-right: 20px; padding-left: 0px">
          <span>Favorites</span>
          <v-icon>mdi-star</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-layout>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon style="margin-left: 10px" color="green"
        >mdi-check-circle-outline</v-icon
      >
      <span>{{ snackbarText }}</span>
      <v-btn text color="#1B5E20" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
      <v-icon color="amber">mdi-alert-outline</v-icon>
      <span style="padding-left: 10px">{{ snackbarText }}</span>
      <v-spacer></v-spacer>
    </v-snackbar>
  </div>
</template>

<script>
// @ is an alias to /src
import store from "@/store/index";
// import Newsletter from "./Newsletter";
import { db, auth } from "@/main";
// import HomeOptions2 from './HomeOptions2'
import HomeIcons from "./HomeIcons";
import BikesHome from "./BikesHome";
// import HomeTestRide from "./HomeTestRide";
// import Footer from "./Footer";
import Footer from "./FooterPressLaunch";
// import Link from "../../components/navbar/LinkComponent.vue";
// import VueCountdown from '@chenfengyuan/vue-countdown'
import { scroller } from "vue-scrollto/src/scrollTo";
import HowToSubs from "./HowToSubs.vue";
// import CustomerTypeForm from "./comp/customer-type-form/CustomerTypeForm.vue";
// import {
//   documentId
// } from '@firebase/firestore';
const now = new Date();
const launchDateTime = new Date("2021-07-15 00:00:00");
export default {
  name: "Home",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        {
          name: "description",
          content: this.metaDesc,
        },
        {
          property: "og:title",
          content: this.metaTitle,
        },
        {
          property: "og:og:image",
          content: this.metaImg,
        },
        {
          property: "og:og:url",
          content: this.metaUrl,
        },
        {
          property: "og:site_name",
          content: "Charged Asia",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
    welcome: String,
    showActionButtons: Boolean,
    isMobileDevice: Boolean,
  },
  data() {
    return {
      Promodialog: false,
      metaTitle: "Charged Asia - Ride for Good!",
      metaDesc:
        "Our mission at Charged is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.",
      metaImg: "",
      metaUrl: "",
      lang: this.$store.state.lang,
      fab: false,
      showBottomNav: false,
      loading: true,
      loadingData: false,
      loadingPromoted: true,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: "",
      titleCarousel: 0,
      airQualityDialog: false,
      NewsRealDialog: false,
      search: "",
      firstSearch: false,
      launch: launchDateTime - now,
      nowList: new Date().getTime(),
      show: true,
      slugLoaded: false,
      resultInterval: null,
      welcomeDialog: false,
      showWelcomeCheck: false,
      tokenDetailDialog: false,
      realnessDialog: false,
      contractAddress: "0x49c248641eb904c9d5a33904d388212a89218187",
      srcPancake: "",
      srcPoocoin: "",
      srcBsc: "",
      srcBscDark: "",
      logoSrc: "",
      logoSrcDark: "",
      logoSrcHeader: "",
      srcTikTok: "",
      tokenItem: {},
      limitPromoted: 10,
      tempImage: "",
      srcPolygon: "",
      srcBinance: "",
      srcKuCoin: "",
      srcTron: "",
      srcPolkadot: "",
      localPromotedTokens: [],
      localLaunchQueueTokens: [],
      videoElement: null,
      videoElementM: null,
      options: {
        quality: {
          default: "1080p",
        },
      },
      emailAddress: null,
      items: [
        {
          src: "/img/home/lebaran/promo-lebaran-bg.png",
        },
        {
          src: "/img/home/homepage-banner-illustration.jpg",
        },
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      popupBanner: true,
      showCustomerTypeForm: false,
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 770) {
        this.showBottomNav = false; // for now
      } else {
        this.showBottomNav = false;
      }
    },
    accType() {
      this.checkUserType();
    },
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    accType() {
      return this.$store.state.user.accType;
    },
    getTheme() {
      return this.$store.state.navigation.theme;
    },
    getLikes() {
      return this.$store.state.user.likes;
    },
    getbyUrl() {
      return this.$store.getters.getbyUrl;
    },
  },
  beforeDestroy() {
    clearInterval(this.resultInterval);
    // console.log("this.videoElement.stop()");
    // this.videoElement.stop();
  },
  destroyed() {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    // console.log(this.$refs.plyr.player)
    // this.videoElement = this.$refs.plyr.player
    if (!localStorage.getItem("modalPromo")) {
      this.Promodialog = true;
      localStorage.setItem("modalPromo", true);
    }
    // const elementPopupGojek = this.$refs.popupBannerGojek.parentNode;
    // elementPopupGojek.style.boxShadow = "none";
  },
  components: {
    // HomeOptions2,
    HomeIcons,
    BikesHome,
    // HomeTestRide,
    // Newsletter,
    // Link,
    Footer,
    HowToSubs,
    // CustomerTypeForm,
  },
  created() {
    store.commit("ShowNav");
    this.currentUser = auth.currentUser;
    this.init();
    setTimeout(() => {
      this.currentUser = auth.currentUser;
      // console.log("this.currentUser");
      // console.log(this.currentUser);
    }, 1000);

    if (this.getbyUrl) {
      this.$store.commit("setbyUrl", false);
      // console.log("THIS_SESSION_URL", this.getbyUrl);
    }
  },
  methods: {
    init() {
      // console.log("### Home window width #######");
      // console.log(this.windowWidth);
      this.loading = true;
      this.loadingPromoted = true;
      this.showButtons();
      this.srcTikTok = "./img/icons/tiktok_light_orange.png";
      this.srcTikTokWhite = "./img/icons/tiktok_white.png";
      this.srcPancake = "./img/icons/pancakeswap.png";
      this.srcPoocoin = "./img/icons/poocoin.png";
      this.srcBsc = "./img/icons/bscscan_light.png";
      this.srcBscDark = "./img/icons/bscscan_dark.png";
      this.srcPolygon = "./img/icons/polygon.png";
      this.srcBinance = "./img/icons/binance-coin-logo.png";
      this.srcKuCoin = "./img/icons/kucoin.png";
      this.srcTron = "./img/icons/tron.png";
      this.srcPolkadot = "./img/icons/polkadot.png";
      // console.log(this.windowHeight);
      // console.log("this.windowHeight");
    },
    routerGo(route) {
      this.$router.push(route);
    },
    goToLink(link) {
      window.open(link, "_blank");
    },
    setShowCustomerTypeForm(isShow) {
      this.showCustomerTypeForm = isShow;
    },
    checkUserType() {
      // Check User Type
      this.currentUser = auth.currentUser;
      // console.log(this.currentUser);
      // console.log("this.getUser.accType");
      // console.log(this.getUser.accType);
      if (this.currentUser && this.getUser.accType > 9) {
        // console.log("####### Welcome App User #######");
        // this.$router.push('/account')
        return;
      } else if (this.currentUser && this.getUser.accType === 1) {
        // console.log("####### Welcome Admin User #######");
        this.$router.push("/charged");
        return;
      }
    },
    onReady() {
      // console.log("Player is ready!");
      // console.log(event)
      if (this.videoElement === null) {
        this.videoElement = this.$refs.plyr.player;
        this.videoElement.muted = true;
        this.videoElement.elements.buttons.play[0].classList = [];
        this.videoElement.elements.buttons.play[0].classList.add(
          "button-hide-me"
        );
        this.videoElement.elements.container.classList.add(
          "plyr--init-hide-controls"
        );
        // this.videoElement.elements.container.classList.add('plyr__control plyr__control--overlaid')
        // console.log(this.videoElement);
        this.videoElement.play();
      }
    },
    updateElement() {
      // console.log('Set videoElement > event.target')
      // console.log(event)
      if (this.videoElement === null) {
        this.videoElement = this.$refs.plyr.player;
        this.videoElement.play();
      } else {
        // console.log("Already playing jey!");
      }
    },
    videoEnded(event) {
      console.log(event);
      this.videoElement.play();
    },
    onReadyMobile() {
      // console.log("Player is ready!");
      // console.log(event)
      if (this.videoElementM === null) {
        this.videoElementM = this.$refs.plyr_mobile.player;
        this.videoElementM.muted = true;
        this.videoElementM.elements.buttons.play[0].classList = [];
        this.videoElementM.elements.buttons.play[0].classList.add(
          "button-hide-me"
        );
        this.videoElementM.elements.container.classList.add(
          "plyr--init-hide-controls"
        );
        // this.videoElement.elements.container.classList.add('plyr__control plyr__control--overlaid')
        // console.log(this.videoElementM);
        this.videoElementM.play();
      }
    },
    updateElementMobile() {
      // console.log('Set videoElement > event.target')
      // console.log(event)
      if (this.videoElementM === null) {
        this.videoElementM = this.$refs.plyr_mobile.player;
        this.videoElementM.play();
      } else {
        // console.log("Already playing jey!");
      }
    },
    videoEndedMobile(event) {
      console.log(event);
      this.videoElementM.play();
    },
    connectWallet() {
      this.$emit("connectWalletClicked");
    },
    showButtons() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
        this.loading = false;
        // console.log('this.getUser.networkChainID')
        // console.log(this.getUser.networkChainID)
      }, 1500);
    },
    searchClicked(e) {
      // console.log("search");
      if (e === "click" && this.search !== "") {
        this.firstSearch = false;
        this.searchAll();
        return;
      }
      if (e.keyCode === 13 && this.search !== "") {
        // alert('Enter was pressed')
        this.firstSearch = false;
        this.searchAll();
        return;
      }
      if (!this.firstSearch) {
        this.searchTrigger = false;
      }
    },
    searchAll() {},
    timer(ms) {
      return new Promise((res) => setTimeout(res, ms));
    },
    likeThisItem(item) {
      if (this.getLikes.indexOf(item.id) > -1) {
        this.snackbarText = "You have already liked this item";
        this.snackbarWarning = true;
        return;
      }
      store.commit("SetLikes", item.id);
      let dispatchObj = {
        likes: (item.likes += 1),
      };
      db.collection("tokens")
        .doc(item.id)
        .update(dispatchObj)
        .then(() => {
          // console.log('Item likes in news bucket updated')
          this.snackbarText = "Your like has been recorded";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    lookupLiked(item) {
      // console.log(item)
      return this.getLikes.indexOf(item.id) > -1;
    },
    openTokenDetail(item) {
      this.tokenItem = item;
      this.tokenDetailDialog = true;
      if (item.contract_address !== "") {
        this.$router.replace("/token/" + item.contract_address);
      } else {
        this.$router.replace("/token/" + item.slug);
      }
      setTimeout(() => {
        this.$refs.tokenDetailComp.checkNewTokenOpened();
      }, 1000);
      this.countViews(item);
    },
    openTokenDetailSlug(item) {
      this.tokenItem = item;
      this.tokenDetailDialog = true;
      setTimeout(() => {
        this.$refs.tokenDetailComp.checkNewTokenOpened();
      }, 1000);
      this.countViews(item);
    },
    countImpressions(arr) {
      for (var i in arr) {
        let dispatchObj = {
          impressions: parseInt(arr[i].impressions) + 1,
        };
        db.collection("tokens")
          .doc(arr[i].id)
          .update(dispatchObj)
          .then(() => {
            // console.log('Impressions  in token bucket updated')
            // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
            // this.snackbar = true
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    countViews(item) {
      let dispatchObj = {
        views: (item.views += 1),
      };
      db.collection("tokens")
        .doc(item.id)
        .update(dispatchObj)
        .then(() => {
          // console.log('Item views in token bucket updated')
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeTokenDetail() {
      this.tokenDetailDialog = false;
      this.$router.replace("/");
      if (this.slugLoaded) {
        this.loadPromotedTokens();
        // Set the interval for loading the price quotes every minute
        this.resultInterval = setInterval(() => {
          // this.pollPromotedTokens()
        }, 60000);
        this.slugLoaded = false;
      }
    },
    closeWelcomeClicked() {
      this.welcomeDialog = false;
      store.commit("setWelcomeSession", true);
      if (this.showWelcomeCheck) {
        store.commit("setWelcome", true);
        if (this.getUser.isLoggedIn) {
          this.setWelcomeUser();
        }
      }
      const firstScrollTo = scroller();
      setTimeout(() => {
        firstScrollTo("#home");
      }, 200);
    },
    setWelcomeUser() {
      var obj = {
        welcome: true,
      };
      this.saveSettingsData(obj);
    },
    saveSettingsData(obj) {
      db.collection("users")
        .doc(this.getUser.docId)
        .update(obj)
        .then(() => {
          // console.log('User Account in bucket updated')
        })
        .catch((error) => {
          console.log(error);
        });
    },
    scrollToBikesHome(element) {
      // const element = document.getElementById("#bikeshome")
      // const element = this.$refs.bikesHome
      console.log(element);
      element.scrollIntoView();
    },
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({
        behavior: "smooth",
      });
    },
    openTerms() {
      this.$emit("termsClicked");
    },
    openPrivacy() {
      this.$emit("privacyClicked");
    },
    openRealness() {
      this.realnessDialog = true;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    gotoLink(link) {
      window.open(link, "_blank");
    },
    handleSuccess(e) {
      console.log(e);
      this.snackbarText = "Contract Address copied.";
      this.snackbar = true;
    },
    handleError(e) {
      console.log(e);
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    timeDifference(previous) {
      var current = Math.round(new Date() / 1000);
      // console.log(current)
      var msPerMinute = 60;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;

      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " Sec ago";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " Min ago";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " Hrs ago";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " Days ago";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " Months ago";
      } else {
        return Math.round(elapsed / msPerYear) + " Years ago";
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.homeTitle {
  color: #fff;
  font-size: 34px;
  font-weight: 300;
  text-align: center;
  filter: drop-shadow(-5px 5px 5px #000);
  -webkit-filter: drop-shadow(-5px 5px 5px #000);
  // text-transform: uppercase;
}

.homeTitleNew {
  color: #000;
  font-family: "Montserrat";
  font-size: 68px;
  line-height: 85px;
  font-weight: 700;
}

.homeTitleNewMobile {
  color: #000;
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.clockText {
  // font-family: 'Digital-7', monospace;
  font-size: 22px;
  // transform: translate(-50%, -50%);
  color: #1b5e20;
  text-shadow: 0 0 2px rgba(163, 163, 163, 1), 0 0 2px rgba(163, 163, 163, 1);
}

.clockDarkText {
  // font-family: 'Digital-7', monospace;
  font-size: 22px;
  // transform: translate(-50%, -50%);
  color: #f9fbe7;
  text-shadow: 0 0 2px rgba(163, 163, 163, 1), 0 0 2px rgba(163, 163, 163, 1);
}

.clock {
  font-family: "Digital-7", monospace;
  font-size: 40px;
  // transform: translate(-50%, -50%);
  color: #1b5e20;
  text-shadow: 0 0 5px rgba(163, 163, 163, 1), 0 0 5px rgba(163, 163, 163, 1);
}

.clockDark {
  font-family: "Digital-7", monospace;
  font-size: 40px;
  // transform: translate(-50%, -50%);
  color: #f9fbe7;
  text-shadow: 0 0 5px rgba(163, 163, 163, 1), 0 0 5px rgba(163, 163, 163, 1);
}

.myBgLight {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url("/img/home/home_light_02.jpeg") no-repeat;
  background-size: cover;
}

.myBgDark {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)),
    url("/img/home/home_dark_03.jpeg") no-repeat;
  background-size: cover;
}

.myBgLightMobile {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url("/img/home/home_light_mobile.png") no-repeat;
  background-size: cover;
}

.myBgDarkMobile {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url("/img/home/home_light_mobile.png") no-repeat;
  background-size: cover;
}

.v-skeleton-loader__image {
  height: 500px;
  border-radius: 0;
}

.wrap-text {
  word-break: normal;
}

.plyr--init-hide-controls .plyr__controls {
  display: none;
}

.button-hide-me {
  display: none;
}

.testRideButton {
  font-size: 24px;
  font-weight: 600;
  border-radius: 10px;
  border: 3px solid #fff;
  width: 250px;
  color: #fff;
  text-transform: none !important;
  background-color: #ffffff30;
}

/* DESQY ADD THIS */

/* FONT */
p {
  font-family: "Work Sans";
}

.montserrat {
  font-family: "Montserrat" !important;
}

.work-sans {
  font-family: "Work Sans";
}

/* END FONT */

// DOTS V-CAROUSEL
.v-btn--icon.v-size--small {
  font-size: 18px;
}

.theme--light.v-btn.v-btn--icon {
  color: rgb(0 0 0 / 0%);
}

// END DOTS V-CAROUSEL

.stepper-wrapper {
  font-family: Arial;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #6b4693;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #6b4693;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 52px;
  border: 1px solid #a57fb6;
}

.stepper-text {
  font-size: 14px;
  color: #535456;
  letter-spacing: -0.02em;
  max-width: 242px;
}

// Media Query

@media (max-width: 768px) {
  .stepper-item {
    font-size: 16px;
  }

  .stepper-text {
    font-size: 16px;
    max-width: 16em;
  }

  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }

  .stepper-item .step-counter {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }

  .stepper-item.active {
    font-weight: bold;
  }

  .stepper-item.completed .step-counter {
    background-color: #6b4693;
  }

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #6b4693;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }

  .stepper-item:first-child::before {
    content: none;
  }

  .stepper-item:last-child::after {
    content: none;
  }

  .v-text-field--filled.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot {
    min-height: 52px;
    border: 1px solid #a57fb6;
  }

  .stepper-text {
    font-size: 14px;
    color: #535456;
    letter-spacing: -0.02em;
    max-width: 242px;
  }

  // Media Query

  @media (max-width: 768px) {
    .stepper-item {
      font-size: 16px;
    }

    .stepper-text {
      font-size: 16px;
      max-width: 16em;
    }
  }
}

.bg-home {
  background-image: url("/img/home/homepage-banner-illustration.jpg");
  background-size: cover;
  background-position: center center;
  // background-attachment: fixed;
}

.bg-home-mobile {
  background-image: url("/img/home/homepage-banner-illustration-mobile.jpg");
  background-size: cover;
}

.bg-lebaran {
  background-image: url("/img/home/lebaran/promo-lebaran-bg.png");
  background-size: cover;
  background-position: center center;
}

.bg-lebaran-mobile {
  background-image: url("/img/home/lebaran/promo-lebaran-bg-mobile.png");
  background-size: cover;
  min-height: 720px;
}

.bg-promo {
  background-image: url("/img/home/homepage_banner_new_201023_desk.jpeg");
  background-size: cover;
  // background-position: center center;
  background-position: top -50px center;
}

.bg-promo-mobile {
  background-image: url("/img/home/homepage_banner_new_201023_mobile.png");
  background-size: cover;
  background-position-x: center;
  min-height: 720px;
}

.bg-rimau-s {
  background-image: url("/img/shared/bikes/rimau_s/bg-banner-rimau_s-new.jpg");
  background-size: cover;
  background-position: center center;
}

.bg-rimau-s-mobile {
  background-image: url("/img/shared/bikes/rimau_s/bg-banner-rimau_s-mobile.jpg");
  background-size: cover;
}

// END Media Query

@media (min-width: 414px) {
  .bg-lebaran-mobile {
    min-height: 786px !important;
  }

  .bg-promo-mobile {
    min-height: 786px !important;
  }
}

@media (min-width: 360px) {
  .bg-lebaran-mobile {
    min-height: 690px;
  }

  .bg-promo-mobile {
    min-height: 773px;
  }
}

// END Desqy CSS
</style>